import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <div className="content-sidebar">
      <div className="content">
      <h1 className="font-bold text-3xl md:text-5xl lg:text-8xl mb-5">Ideas are great. Executing is better. A11y Site</h1>
    <h2 className="font-bold text-3xl">Description</h2>
    <p>

Build a responsive platform to allow me to be creative, expressive, find a voice and build useful 'things'. A goto point for A11y information and a place to capture my understandings, available to the world.

</p>
 
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
    <p>
      
      
      
      {/* <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </p>
      </div>
      <div className="sidebar">
      <ul>
        
        <li><Link to="/theplan/">Go to The Plan</Link></li>
        <li><Link to="/brand/">Brand Demo</Link></li>
        <li><Link to="/graph/">Posts on GraphCMS (started blog template)</Link></li>
        <li><Link to="/colours/">Accessibility - colour functions in use - converted to react helpers</Link></li>

        <li><a href="https://www.youtube.com/watch?v=fqImaAnPNvA" target="_blank" rel="noreferrer">YouTube Video Gatsby and GraphCMS</a></li>
        <li><a href="https://app.graphcms.com/f9041e906adf438dbea40ab0e54fab0f/master" target="_blank" rel="noreferrer">GraphCMS</a></li>

        <li><a href="https://www.youtube.com/playlist?list=PLW0RabRDhwwzVNhlOgQQgw6HJzXdM1MnT" target="_blank" rel="noreferrer">Gatsby Course by Skillthrive on YouTube</a></li>
        
        
        <li>https://api-eu-central-1.graphcms.com/v2/ckm50en9gryqq01z5gzvq793x/master - graphCMS API link</li>


      </ul>
      </div>

    </div>
    


    
  </Layout>
)

export default IndexPage
